import Autoplay from "embla-carousel-autoplay"
import useEmblaCarousel from "embla-carousel-react"
import { EmblaOptionsType } from "embla-carousel-react"
import Link from "next/link"
import { memo } from "react"

import { ReactComponent as Lv80 } from "@spatialsys/assets/img/press-carousel/80-level.svg"
import { ReactComponent as BeyondGames } from "@spatialsys/assets/img/press-carousel/beyond-games.svg"
import { ReactComponent as Chosun } from "@spatialsys/assets/img/press-carousel/chosun.svg"
import { ReactComponent as Forbes } from "@spatialsys/assets/img/press-carousel/forbes.svg"
import { ReactComponent as Hankyung } from "@spatialsys/assets/img/press-carousel/hankyung.svg"
import { ReactComponent as Mirakle } from "@spatialsys/assets/img/press-carousel/mirakle.svg"
import { ReactComponent as PocketTactics } from "@spatialsys/assets/img/press-carousel/pocket-tactics.svg"
import { ReactComponent as Telefonica } from "@spatialsys/assets/img/press-carousel/telefonica.svg"
import { ReactComponent as VentureBeat } from "@spatialsys/assets/img/press-carousel/venture-beat.svg"
import { ReactComponent as Wired } from "@spatialsys/assets/img/press-carousel/wired.svg"
import XRToday from "@spatialsys/assets/img/press-carousel/xr-today.webp"
import { Button, Heading } from "@spatialsys/web/ui"

const OPTIONS: EmblaOptionsType = { dragFree: true, loop: true, speed: 5 }

const SLIDES = [
  {
    title:
      "“We specifically chose to work with Spatial because it is available on VR, web and mobile.” Aside from being a metaverse debut for Hublot, the Spatial activation also provides a revealing insight into the direction that marketing is headed.",
    Icon: Forbes,
    link: "https://www.forbes.com/sites/stephaniehirschmiller/2022/11/08/hublot-brings-fifa-soccer-world-cup-to-the-metaverse/",
  },
  {
    title:
      "Now, you can play a game like the kind Spatial is selling—something between a casual mobile game and a massive AAA title—right in the browser.",
    Icon: Wired,
    link: "https://www.wired.com/story/spatial-social-gaming/",
  },
  {
    title:
      "We’ve taken away all complexity for developers who can for the first time build complex and thrilling games in the browser",
    Icon: VentureBeat,
    link: "https://venturebeat.com/games/spatial-announces-its-first-season-of-in-house-web-based-games/",
  },
  {
    title:
      "Jack Daniel’s opened its virtual doors to the public last September with a metaverse experience using technologies from XR firm Spatial. Fans can leverage Spatial’s XR platform to access the first-ever experience for the beverage firm across smartphones, tablets, PCs, and VR headsets.",
    Icon: null,
    link: "https://www.xrtoday.com/virtual-reality/jack-daniels-iw-group-honour-hip-hop-with-spatial-metaverse/",
  },
  {
    title:
      "Spatial Studio provides a centralized platform where you can easily see and oversee all your creations. This powerful tool empowers you to shape and mold your Worlds, enabling you to craft massive, intertwined experiences.",
    Icon: Telefonica,
    link: "https://engineering.telefonica.com/spatial-the-new-metaverse-platform-for-virtual-events-c45fa5f3ddbb/",
  },
  {
    title:
      "Social gaming company Spatial releases UGC games platform rivaling Roblox and its first season of in-house games, kicking off new era",
    Icon: PocketTactics,
    link: "https://www.pockettactics.com/spatial/ugc-games",
  },
  {
    title:
      "Spatial is leveraging the vast Unity community and offering monetization options that make it a truly viable option for any developer",
    Icon: Lv80,
    link: "https://80.lv/articles/spatial-announces-first-season-of-in-house-and-ugc-games/",
  },
  {
    title:
      "The cloud-based platform aims to make it easy for brands and developers to embrace a web-focused approach to user generated content",
    Icon: BeyondGames,
    link: "https://www.beyondgames.biz/44294/spatial-empowers-developers-with-ugc-platform-to-bolster-web-gaming/",
  },
  {
    title: "높은 수준의 그래픽과 편의성, 손쉬운 콘텐츠 개발 도구가 강점",
    Icon: Hankyung,
    link: "https://www.hankyung.com/economy/article/2023062151371",
  },
  {
    title: "대작 게임 수준의 그래픽을 웹에서 구동",
    Icon: Chosun,
    link: "https://biz.chosun.com/it-science/ict/2023/01/28/AKQ6QDJ2ABEDDK7DPAUIXEK3GI",
  },
  {
    title: "정교한 가상 경험을 제시하는 메타버스 플랫폼",
    Icon: Mirakle,
    link: "https://www.mk.co.kr/news/it/10574573",
  },
]

export const PressCarousel = memo(function PressCarousel() {
  const [emblaRef] = useEmblaCarousel(OPTIONS, [Autoplay({ delay: 5000 })])

  return (
    <div className="relative mt-6 md:mt-10">
      <div className="pointer-events-none absolute bottom-0 left-0 right-0 top-0 z-10 hidden bg-[linear-gradient(90deg,#171717,transparent_15%,transparent_85%,#171717)] lg:block" />
      <div ref={emblaRef} className="overflow-hidden">
        <div className="flex [backface-visibility:hidden] [touch-action:pan-y]">
          {SLIDES.map(({ title, Icon, link }) => (
            <div className="flex-shrink-0 flex-grow-0 basis-4/5 pl-6 xs:basis-2/5 md:basis-[30%]" key={title}>
              <div className="relative flex h-full flex-col items-start justify-between overflow-hidden rounded-3xl bg-radial-blur px-8 py-6">
                <div className="z-10">
                  {Icon ? <Icon /> : <img src={XRToday.src} alt="XR Today Logo" />}
                  <div className="mt-4">
                    <Heading size="h5" weight="black" className="lg:text-h4" asChild>
                      <p>{`"${title}"`}</p>
                    </Heading>
                  </div>
                </div>
                <Button
                  color="white"
                  variant="text"
                  as={Link}
                  href={link}
                  rel="noreferrer"
                  target="_blank"
                  className="z-10 mt-5 p-0"
                >
                  View
                </Button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
})
